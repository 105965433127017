<template>
    <app-wrapper>
        <template v-slot:child>
            <div class="w-90 center">
                <div class="flex flex-wrap justify-between items-center pv3">
                    <div>
                        <p class="pb2 f3 b">
                            Stock Transfer
                            <strong
                                ><font-awesome-icon
                                    icon="fa-solid fa-question-circle fa-2xs"
                                    class="text-muted clickTooltipIcon"
                                    @click="toggleTooltip = true"
                                    style="cursor: pointer"
                            /></strong>
                        </p>
                        <div class="dashboard-inquiry-modal" v-if="toggleTooltip">
                            This shows an overview of all your customers, including suppliers, debtors and investors. Click
                            <a href="#" target="_blank" style="color: blue">here</a> to learn more.
                        </div>
                    </div>
                    <div class="scrollmenu flex">
                        <router-link
                            active-class="active"
                            :to="{ name: 'TransferStock', query: { id: $route.params.id } }"
                            v-if="(state.status === 'pending' || state.status === 'draft') && (role === 'owner' || role === 'admin' || rolePermissions?.includes(63))"
                        >
                            <button class="mr2 flex items-center" style="height: 45px">Edit</button>
                        </router-link>
                        <!-- <router-link :to="{ name: 'StockTransfers' }" class="btn">Back</router-link> -->
                        <button
                            style="background-color: white; border: 1px solid #132C8C; color: #132C8C;"
                            class="dropdown-togglex "
                            role="button"
                            id="dropdownMenuLink"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                        >
                            More <font-awesome-icon icon="fa-solid fa-caret-down" class="fa-2x" />
                        </button>
                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink" style=" z-index: 2000">
                            <li v-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(62)" style="cursor: pointer" @click="duplicateStockTransfer($route.params.id)">Duplicate</li>
                            <li v-if="state.status === 'pending' && (state.destinationBusiness?.id || state.destinationBusiness) === orgData.orgId && (role === 'owner' || role === 'admin' || rolePermissions?.includes(65))">
                                <a
                                    href="#"
                                    class="dropdown-itemx"
                                    @click="actOnStockTransfer($route.params.id, 'approved')"
                                >
                                    Approve
                                </a>
                            </li>
                            <li v-if="state.status === 'pending' && (state.destinationBusiness?.id || state.destinationBusiness) === orgData.orgId && (role === 'owner' || role === 'admin' || rolePermissions?.includes(65))">
                                <a
                                    href="#"
                                    class="dropdown-itemx"
                                    @click="actOnStockTransfer($route.params.id, 'rejected')"
                                >
                                    Reject
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="flex justify-start">
                    <div class="w-100 w-30-l">
                        <h4>Transfer Details</h4>
                        <p>
                            Adding details to this transfer helps staff stay on the top of transfers and easily identify outgoing
                            and incoming items
                        </p>
                    </div>
                    <div class="w-100 w-70-l">
                        <div class="flex flex-wrap justify-between">
                            <div class="flex flex-column pb3 form-spacing">
                                <label class="pb2 font-w2">Source Business</label>
                                <select
                                    class="form-sign"
                                    v-model="state.sourceBusiness"
                                    name="sourceBusiness"
                                    id="sourceBusiness"
                                    style="padding: 10px"
                                    disabled
                                >
                                    <option value="">Select source business</option>
                                    <option v-for="business in state.businesses" :key="business._id" :value="business.org">
                                        {{ business.name }}
                                    </option>
                                </select>
                            </div>
                            <div class="flex flex-column pb3 form-spacing">
                                <label class="pb2 font-w2">Destination Business</label>
                                <select
                                    class="form-sign"
                                    v-model="state.destinationBusiness"
                                    name="destinationBusiness"
                                    id="destinationBusiness"
                                    style="padding: 10px"
                                    disabled
                                >
                                    <option value="">Select destination business</option>
                                    <option v-for="business in state.businesses" :key="business._id" :value="business.org">
                                        {{ business.name }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="flex flex-wrap justify-between">
                            <div class="flex flex-column pb3 form-spacing">
                                <label class="pb2 font-w2">Reference</label>
                                <input
                                    class="form-sign"
                                    v-model="state.reference"
                                    name="reference"
                                    id="reference"
                                    style="padding: 10px"
                                    disabled
                                />
                            </div>
                            <div class="flex flex-column pb3 form-spacing">
                                <label class="pb2 font-w2">Select a date</label>
                                <input
                                    type="date"
                                    class="form-sign"
                                    v-model="state.date"
                                    name="date"
                                    id="date"
                                    style="padding: 10px"
                                    disabled
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <hr />
                <div class="flex justify-start">
                    <div class="w-100 w-30-l">
                        <h4>Products</h4>
                        <p>Choose products to add to this transfer</p>
                    </div>
                    <div class="w-100 w-70-l">
                        <div v-if="state.products.length" class="pb3">
                            <table class="table table-bordered table-striped" cellspacing="0">
                                <caption></caption>
                                <thead>
                                    <tr>
                                        <th>Product</th>
                                        <th>Inventory at source</th>
                                        <th>Inventory at destination</th>
                                        <th>Quantity</th>
                                        <th>Cost price ({{ $store.state.Settings?.currency || 'NGN' }})</th>
                                        <th>Total price ({{ $store.state.Settings?.currency || 'NGN' }})</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="product in state.products" :key="product.id">
                                        <td class="vertical-align-middle">{{ product.name }}</td>
                                        <td class="vertical-align-middle">{{ product.source_qty }}</td>
                                        <td class="vertical-align-middle">{{ product.destination_qty }}</td>
                                        <td class="vertical-align-middle">
                                            <input 
                                                type="number" 
                                                class="product-input-2" 
                                                v-model="product.quantity"
                                                :max="product.source_qty"
                                                disabled
                                            />
                                        </td>
                                        <td class="vertical-align-middle">
                                            <input 
                                                type="number" 
                                                class="product-input-2"  
                                                v-model="product.price"
                                                disabled
                                            />
                                        </td>
                                        <td class="vertical-align-middle">{{ formatAmount(product.quantity * product.price, $store.state.Settings?.currency || 'NGN') }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <!-- <v-select
                            label="name"
                            placeholder="Enter product name"
                            class="pv2 ph3"
                            :filterable="false"
                            :options="state.options"
                            @search="onSearchProduct"
                            @option:selected="item => selectProduct(item)"
                            @option:deselected="item => deselectProduct(item)"
                            :disabled="state.sourceBusiness === '' || state.destinationBusiness === '' || state.sourceBusiness === state.destinationBusiness"
                        >
                            <template #open-indicator="{ attributes }">
                                <span v-bind="attributes"></span>
                            </template>
                            <template #no-options="{}">
                                Type to search product..
                            </template>
                            <template slot="option" slot-scope="option">
                                <div class="d-center">
                                    {{ option.name }}
                                </div>
                            </template>
                            <template slot="selected-option" slot-scope="option">
                                <div class="selected d-center">
                                    {{ option.name }}
                                </div>
                            </template>
                        </v-select> -->
                    </div>
                </div>
            </div>
        </template>
    </app-wrapper>
</template>

<script>
import { onMounted, computed, reactive, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import Swal from 'sweetalert2'
import vSelect from 'vue-select'
import AppWrapper from '@/layout/AppWrapper'
import Spinner from '@/components/Spinner'
import ShortUniqueId from 'short-unique-id'
import moment from 'moment-timezone'
import { formatAmount } from '@/utils/lib'

export default {
    name: 'TransferStock',
    components: { AppWrapper, Spinner, vSelect },

    setup() {
        const toggleTooltip = ref(false)
        const store = useStore()
        const router = useRouter()
        const route = useRoute()
        const userData = computed(() => store?.state?.Auth?.userData)
        const productEntrySearchList = computed(() => store.state.Inventory?.productEntrySearchList)
        const rolePermissions = computed(() => store?.state?.Auth?.rolePermissions)
        const role = computed(() => store?.state?.Auth?.role)
        const orgData = computed(() => store?.state?.Auth?.userData)

        const initialState = () => ({
            isProcessing: false,
            sourceBusiness: '',
            destinationBusiness: '',
            reference: new ShortUniqueId({ length: 6 }).randomUUID(),
            date: moment().format('YYYY-MM-DD'),
            products: [],
            businesses: [],
            status: 'draft',
            formMode: 'create',
        })

        const state = reactive(initialState())

        watch(
            () => [state.sourceBusiness, state.destinationBusiness],
            (currValue, prevValue) => {
                if (currValue !== prevValue) { 
                    // state.products = []
                }
        })

        const closeTooltip = () => {
            toggleTooltip.value = false
        }

        const onSearchProduct = (search, loading) => {
            if (search.length) {
                loading(true)
                store.dispatch('Production/searchProduction', { query: search, type: 'ALL', business: state.sourceBusiness }).then(data => {
                    state.options = data.products
                    loading(false)
                })
            }
        }

        const selectProduct = (item) => {
            store.dispatch('Inventory/getTransferStockDestinationQuantity', { productName: item.name, business: state.destinationBusiness })
                .then(data => {
                    state.products.push({
                        name: item.name,
                        product: item._id,
                        price: item?.sellingPrice || 0,
                        source_qty: item?.qty_in_stock || 0,
                        destination_qty: data,
                        quantity: 1,
                        instock: item?.instock || 0,
                        sku: item?.sku || '',
                        id: item._id
                    })
                    state.options = []
                })
        }

        const deselectProduct = (product, item) => {
            
        }

        const duplicateStockTransfer = async (id) => {
            Swal.fire({
                    title: `Confirm Action`,
                    text: `Are you sure you want to duplicate this stock transfer?`,
                    showDenyButton: true,
                    showCancelButton: false,
                    confirmButtonText: 'Yes, Continue!',
                    denyButtonText: `No, Cancel!`
                }).then(async result => {
                    if (result.isConfirmed) {
                        await store.dispatch('Inventory/duplicateStockTransfer', { id }).then( resp => {
                                if (resp.status) {
                                    router.push({
                                        name: 'TransferStock',
                                        query: { id: resp.data }
                                    })
                                }
                            })
                                    
                    }
                })
        }

        const actOnStockTransfer = (id, status) => {
            const title = status === 'approved' ? 'Approve' : 'Reject'
            const content = status === 'approved' ? 'approve' : 'reject'
            Swal.fire({
                title: `${title} Stock Transfer`,
                text: `Are you sure you want to ${content} this stock transfer?`,
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: `Yes, ${title}!`,
                denyButtonText: `No, Cancel!`
            }).then(result => {
                if (result.isConfirmed) {
                    store.dispatch('Inventory/actOnStockTransfer', { id, status })
                        .finally(() => getStockTransfers())
                }
            })
        }

        const getTransferStock = (id) => {
            store.dispatch('Inventory/getTransferStock', { id }).then(resp => {
                const { status, data } = resp
                if (status) {
                    state.sourceBusiness = data?.sourceBusiness?.id || ''
                    state.destinationBusiness = data?.destinationBusiness?.id || ''
                    state.reference = data?.reference
                    state.date = data?.date
                    state.products = data?.products
                    state.status = data?.status
                    state.formMode = 'edit'
                } else {
                    router.push({ name: 'TransferStock' })
                }
            })
        }

        onMounted(() => {
            store.dispatch('Settings/getBusinesses', true).then(resp => {
                if (resp.status) {
                    state.businesses = resp.data
                    state.sourceBusiness = userData.value?.orgId || ''
                }
            }).finally(() => {
                getTransferStock(route.params.id)
            })
            window.addEventListener('click', event => {
                const tooltipIcon = document.querySelector('.clickTooltipIcon')

                if (
                    !(event.target.closest('.clickTooltipIcon') || event.target === tooltipIcon) &&
                    toggleTooltip.value === true
                ) {
                    closeTooltip()
                }
            })
        })

        return {
            state,
            userData,
            orgData,
            role,
            rolePermissions,
            toggleTooltip,
            onSearchProduct,
            selectProduct,
            deselectProduct,
            productEntrySearchList,
            formatAmount,
            duplicateStockTransfer,
            actOnStockTransfer,
        }
    }
}
</script>

<style scoped></style>
